import React from 'react'
import Layout from '../layouts'
import Nav from '../components/nav'

const NotFoundPage = ({location}) => (
	<Layout location={location}>
		<Nav links={{}} location={location} />
		<h1>Nothing here...</h1>
		<p>You just hit a page that doesn&#39;t exist...</p>
	</Layout>
)

export default NotFoundPage
